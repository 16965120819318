@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  /* width */
  ::-webkit-scrollbar {
    @apply w-[10px];
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-secondary-dark shadow-sm rounded-sm;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-primary rounded-sm;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb {
    @apply bg-accent;
  }
}

@layer components {

  .pro-sidebar .pro-inner-item .pro-icon-wrapper {
    @apply bg-primary-dark text-primary-dark
  }

  .navMenu .pro-inner-item .pro-icon svg path {
    @apply text-accent
  }

  .paper {
    @apply bg-white py-6 rounded px-2 overflow-auto;
  }

  .new-prod-input {
    @apply border border-secondary rounded border-opacity-50 outline-none py-1 px-2 w-full max-w-sm;
  }

  .center-inner {
    @apply flex justify-center items-center;
  }

  .ord-details-t-item {
    @apply flex items-center justify-end gap-4 py-3 border-b border-secondary border-opacity-10;
  }

  .chip-primary {
    @apply bg-primary text-white px-1 rounded;
  }

  .ant-btn-custom {
    @apply flex items-center gap-2 justify-center text-secondary;
  }

  .btn {
    @apply px-2 py-1 rounded-sm border duration-300;
  }

  .btn-primary bg-primary-dark text-white hover:text-primary-dark hover:border-primary-dark {
    @apply bg-primary border-primary text-white hover:text-primary hover:bg-opacity-0 hover:border-primary focus:bg-opacity-0 focus:border-primary focus:text-primary;
  }

  .btn-primary bg-primary-dark text-white hover:text-primary-dark hover:border-primary-dark-f {
    @apply bg-primary border-primary text-white hover:text-primary hover:bg-opacity-0 hover:border-primary;
  }

  .btn-danger {
    @apply bg-danger border-danger text-white hover:text-danger hover:bg-opacity-0 hover:border-danger focus:bg-opacity-0 focus:border-danger focus:text-danger;
  }

  .btn-danger-f {
    @apply bg-danger border-danger text-white hover:text-danger hover:bg-opacity-0 hover:border-danger;
  }

  .btn-success {
    @apply bg-success border-success text-white hover:text-success hover:bg-opacity-0 hover:border-success focus:bg-opacity-0 focus:border-success focus:text-success;
  }

  .btn-success-f {
    @apply bg-success border-success text-white hover:text-success hover:bg-opacity-0 hover:border-success;
  }

  .action-btn-container {
    @apply flex items-center gap-1;
  }

  .action-btn {
    @apply text-xl bg-primary w-8 h-8 rounded-sm center-inner text-white hover:text-white hover:bg-opacity-75 duration-300 cursor-pointer;
  }

  .ant-btn.ant-btn-primary {
    @apply text-white bg-primary-dark hover:bg-accent hover:text-secondary-dark
  }

}

.ant-btn-default {
  @apply border-primary-dark text-primary-dark bg-opacity-0
}

.ant-btn.ant-btn-primary {
  @apply text-white bg-primary-dark border-primary-dark hover:bg-accent hover:text-secondary-dark
}

/* dragable table row style */
.row-dragging {
  background: white;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* /dragable table row style */

.ant-modal-wrap {
  z-index: 1050;
}

.ant-image-preview-img {
  display: initial;
}

.details-block .tox-tinymce {
  border-color: transparent !important;
}


@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}